import * as React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import GatsbyNavLink from "./../components/GatsbyNavLink/GatsbyNavLink";
import LangToggle from "./../components/LangToggle/LangToggle";
import ossLogo from "../images/oss_logo_xl.gif";

export default function Header(props) {
  return (
    <Navbar style={{ backgroundColor: "white" }} expand="lg">
      <Container>
        <Navbar.Brand>
          <img
            src={ossLogo}
            alt="OSS Logo"
            width="80"
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <GatsbyNavLink path="/">
              <FormattedMessage id="common.home" />
            </GatsbyNavLink>
            <GatsbyNavLink path="/services">
              <FormattedMessage id="common.our_services" />
            </GatsbyNavLink>
            <GatsbyNavLink path="/team">
              <FormattedMessage id="common.team" />
            </GatsbyNavLink>
            <GatsbyNavLink path="/contact">
              <FormattedMessage id="common.contact" />
            </GatsbyNavLink>
            <GatsbyNavLink path="/imprint">
              <FormattedMessage id="common.imprint" />
            </GatsbyNavLink>
            <LangToggle />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
