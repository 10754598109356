import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "gatsby";
import useLang from "./../hooks/useLang";

export default function Footer() {
  let lang = useLang();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="footer-text">
            <p>
              <strong>
                <FormattedMessage id="common.company_full_name" />
              </strong>
            </p>
            <p>
              <FormattedMessage id="common.company_street" />
              <br />
              <FormattedMessage id="common.company_district" />
              <br />
            </p>
            <p>
              <FormattedMessage id="common.telephone_label_short" />:{" "}
              <FormattedMessage id="common.company_tel_value" />
              <br />
              <FormattedMessage id="common.email_label_short" />:{" "}
              <FormattedMessage id="common.company_email_value" />
            </p>
          </Col>
          <Col className="text-end">
            <Container>
              <Row className="justify-content-end">
                <Col xs="12" lg="auto" className="col-auto">
                  <Link className="footer-link" to={`${lang}/`}>
                    <FormattedMessage id="common.home" />
                  </Link>
                </Col>
                <Col xs="12" lg="auto" className="col-auto">
                  <Link className="footer-link" to={`${lang}/services`}>
                    <FormattedMessage id="common.our_services" />
                  </Link>
                </Col>
                <Col xs="12" lg="auto" className="col-auto">
                  <Link className="footer-link" to={`${lang}/team`}>
                    <FormattedMessage id="common.team" />
                  </Link>
                </Col>
                <Col xs="12" lg="auto" className="col-auto">
                  <Link className="footer-link" to={`${lang}/contact`}>
                    <FormattedMessage id="common.contact" />
                  </Link>
                </Col>
                <Col xs="12" lg="auto" className="col-auto">
                  <Link className="footer-link" to={`${lang}/imprint`}>
                    <FormattedMessage id="common.imprint" />
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
