import * as React from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import { isBrowser, pathPrefix } from "../../util/envUtil";
import gbFlagIcon from "../../images/flags/GB.svg";
import deFlagIcon from "../../images/flags/DE.svg";

export default function LangToggle() {
  let countryIconSrc;
  let toggleLang;
  let titleI18nKey;
  let altText;

  const intl = useIntl();
  if (intl.locale === "de") {
    titleI18nKey = "common.switch_to_english";
    toggleLang = isBrowser()
      ? window.location.pathname.replace("/de", "/en").replace(pathPrefix, "")
      : "/en";
    countryIconSrc = gbFlagIcon;
    altText = "Great Britain flag";
  } else {
    titleI18nKey = "common.switch_to_german";
    toggleLang = isBrowser()
      ? window.location.pathname.replace("/en", "/de").replace(pathPrefix, "")
      : "/de";
    countryIconSrc = deFlagIcon;
    altText = "German flag";
  }

  const titleText = intl.formatMessage({ id: titleI18nKey });

  return (
    <Link
      title={titleText}
      className="nav-link"
      tabIndex={0}
      role="button"
      to={toggleLang}
    >
      <img width={20} height={14} alt={altText} src={countryIconSrc} />
    </Link>
  );
}
