import * as React from "react";
import { Link } from "gatsby";
import useLang from './../../hooks/useLang'

export default function GatsbyNavLink(props) {
    const {children, path} = props
    let lang = useLang();

    return (
        <Link className="nav-link" tabIndex={0} role="button" to={`${lang}${path}`}>{children}</Link>
    );
}
